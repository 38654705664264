import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  min-height: 100vh;
  width: 100%;

  @media only screen and (max-device-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 1rem;
  }
`

export const IconWrapper = styled.div`
  padding-top: 4.5rem;
  padding-bottom: 2rem;
`

export const FormContainer = styled.div`
  min-width: 26rem;
`

export const FormBox = styled.div<{ $withFooter?: boolean; $withBackButton?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  border-radius: ${({ theme }) => theme.border.radiusLarge};
  padding: 2rem;
  min-width: 22rem;
  box-shadow: ${({ theme }) => theme.shadow.regular};
  min-height: 18rem;
`
